.user-profile-friends {
	display: block;
	background: #efefef;
	border-bottom: 1px solid $card-border-color;
	padding-top: .325rem; 
	padding-right: .325rem; 
	min-height: 78px;   
	a.photo-link {
		float: left;
		margin-right: 0;
		width: 12.5%;
		border: 1px solid rgba(255,255,255, .54); 
		margin-left: .325rem;
		margin-bottom: .325rem;
		img {
			 display: block; 
			 width: 100%; 
		}
	}  
}