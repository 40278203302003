.list-appointments {
	list-style: none;
	padding-left:0;
	li {
	    margin-left: 100px;
	    border-left: 1px solid $border-color;
	    position: relative;
	    &:first-of-type {
	    	padding: 1.25rem 0 0 0;
	    	margin-top: 1.25rem;
	    	.item-time, .item-icon {
    			top: 0 !important;
    		}	
	    }

		.item-time {
			position: absolute;
		    left: -90px;
		    top: -.625rem;
		    width: 70px;
		    text-align: right;
		    font-size: .9rem;
		}
		.item {
			border-bottom: 1px solid lighten($border-color, 5%);
				padding: 1rem;
			.item-icon {
				$item-icon-width: 24px;

				position: absolute;
			    text-align: center;
			    left: -$item-icon-width/2;
			    top: -$item-icon-width/2;
			    width: $item-icon-width;
			    height: $item-icon-width;
			    line-height: $item-icon-width;

			    i {
			    	font-size:16px; 
			    	position: relative; 
			    	top:-1px;

			    }

			    color: #fff;
			    background: $brand-primary;
			    border-radius: 50%;
			}
			
			a:hover .text-muted { color:#fff;}
		}
	}
	@include media-breakpoint-down(md) {
		li {
			margin-left:2rem;
			.item {
				padding:1.5rem;
			}
			&:first-of-type {
				.item-time { margin-top:2px;	}
			}
			.item-time {
				left:1.5rem;				
				text-align:center;
				background:#fff;
				width:auto;
			}
			.item a .text-muted {
				display:none;
			}
		}
	}
}