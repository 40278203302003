.navbar .dropdown-menu {
	margin:0;
}
.dropdown-menu.notifications {
	min-width: 280px;
	padding:0;
	> .dropdown-title {
		padding: $grid-gutter-width/2;
		text-transform: uppercase;
		font-weight: 500;
		font-size: .9rem;
		line-height: normal;
		color: rgba(0,0,0,.54);
		border-bottom: 1px solid $border-color;
	}
	.dropdown-action {
		line-height: 1rem;
		padding: $grid-gutter-width/2;
	}
	> .dropdown-item {
		padding: $grid-gutter-width/2;
		line-height: 1.3;
		font-size: .9rem;
		border-bottom: 1px solid $border-color;
		.media-left {
			padding-right: $grid-gutter-width/2;
		}
		a.nav-link {
			position:relative;
			display: block;
			strong {
				display: block;
				color: $gray;
			}
			color: $text-muted;
			i {
				opacity: .5;
			}
		} 
		&:hover {
			border-color: $gray-lighter;
			background: rgba(0,0,0, .05);
			a.nav-link i {
				opacity: 1;
				color: $brand-primary;
			}
		}
	}
	@include media-breakpoint-down(md) {
		min-width: 220px;
		.dropdown-item small {
			display: block;
		}
	}
}