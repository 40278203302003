.card-user-cover {
	.preview {
		overflow: hidden;
		@include media-breakpoint-up(sm) {
			max-height: 300px;
		}
		img {
			max-height: 200px;
			width: 100%;
			display: block;
		}
	}
	.user {
		position: absolute;
		a.avatar {
			position: absolute;
			bottom: -40px;
			left: 25px;
			border: 3px solid #fff;
			border-radius: 50%;
		}
	}
	ul.menu {
		padding-left: 115px;
		margin: 0;
		height: 55px;
		list-style: none;
		background: #fff;
		li {
			display: inline-block;
			&.active a {
				color: $gray-dark;
			}
			a {
				height: 55px;
				line-height: 53px;
				margin-right: 5px;
				@include media-breakpoint-up(xs) {
					margin-right: 15px;
				}
				color: #ccc;
				font-size: 16px;
				i.md {
					font-size: 24px;
					vertical-align: middle;
					top: -2px;
					margin-right: 5px;
				}
				&:hover {
					color: $gray-dark;
					text-decoration: none;
				}
				@include media-breakpoint-down(xs) {
					span {
						display: none
					}
				}
			}
		}
	}
}