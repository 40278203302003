#notes {
	.container-fluid {
		height: 100%;
		padding-bottom:20px;
	}
	textarea {
		border: none;
		background: none;	
		height:100%;
	}
}