.sidebar-dark {
	.sidebar-user img { 
		border: 1px solid $sidebar-dark-border-color;
	}
	.sidebar-user-center {
		background: rgba(0, 0, 0, .05);
		border-bottom: 1px solid $sidebar-dark-border-color;
		
		.sidebar-user-avatar img {
			border: 1px solid $sidebar-dark-border-color;
		}
		.user-info > a {
			color: $sidebar-dark-link-color;
		}
		.user-extras a { 
			color: $sidebar-dark-text-color;
			&:hover {
				color: $sidebar-dark-link-color;
				text-decoration: none;
			}
		}
	}
	.sidebar-activity {
		li { 
			border-bottom: 1px solid $sidebar-dark-border-color;
			&, a {
				color: $sidebar-dark-text-color;
			}
			small { 
				color: rgba(255, 255, 255, .34);
			}
			.sidebar-activity-icon {
				background: rgba(255, 255, 255, .12);
			}
			&:hover, &.active {
				background: rgba(255, 255, 255, .12);
				&, 
				a {
					color: $sidebar-dark-link-color;
					transition: all .7s;
				}
			}
		} 
	}
	.sidebar-email .sidebar-email-item {
		border-bottom: 1px solid $sidebar-dark-border-color;
		a.nav-link {
			color: $sidebar-dark-text-color;
		}
		&.new a:after {
			background: #ffffff;
		}
		&:hover,
		&.active a.nav-link, 
		&.active a.nav-link:hover { 
			background: rgba(255, 255, 255, .12);
			.sidebar-email-message {
				color: $sidebar-dark-text-color;
			}
			* {
				color:#fff; 
			}
		}
	}
}